<template>
  <svg width="14" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M.2407 1.6508 1.7592.3492 7 6.4634 12.2407.3492l1.5186 1.3016L7 9.5366.2407 1.6508Z"
      fill="#212B56"
    />
  </svg>
</template>
